@import "palette.less";
.bg-site-primary {
   background-color: @color-site-primary;
}
.bg-site-primary-opaque {
   background-color: fade(@color-site-primary, 50%);
}
.bg-site-secondary {
   background-color: @color-site-secondary;
}
.bg-site-secondary-opaque {
   background-color: fade(@color-site-secondary, 50%);
}
.bg-site-tertiary {
   background-color: @color-site-tertiary;
}
.bg-site-tertiary-opaque {
   background-color: fade(@color-site-tertiary, 50%);
}
.bg-gray {
   background-color: #e9ecef !important;
}

body {
   /*height: 100%;*/
   /*https://adoption.azureedge.net/wp-content/custom-backgrounds-gallery/VIVA-background-office-06.jpg*/
   /*background: url(/assets/images/background/LEAD-background-road.jpg) no-repeat center center fixed;
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;*/
   background-color: #e2eff3; /*#fff*/
}

/*https://coder-coder.com/background-image-opacity/*/
/*.bg-page-container {
   position: relative;
   height: 100vh;
   width: 100%;
}

.bg-page-container::before {
   content: "";
   background-image: url('https://adoption.azureedge.net/wp-content/custom-backgrounds-gallery/VIVA-background-office-06.jpg');
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
   position: absolute;
   top: 0px;
   right: 0px;
   bottom: 0px;
   left: 0px;
   opacity: 0.5;
}
*/
