@import "palette.less";
/*toastr overrides:
  parent toastr classes are not acting right - adding !important
*/
#toast-container > div {
   opacity: .95!important;
}
.toast-success {
   background-color: #51A351 !important
}

.toast-error {
  background-color: #BD362F !important
}

.toast-info {
  background-color: #2F96B4 !important
}

.toast-warning {
  background-color: #F89406 !important
}

/* bootstrap overrides */

.flex-basis-auto {
   flex-basis: auto
}

.alert-secondary {
   color: #fff;
   background-color: #287399;
   border-color: #287399;
}

.card {
  //border: 1px solid @color-site-primary;
}

.card-header {
  color: @color-site-primary;
  border-bottom: 1px solid @color-site-quinary;
}

.btn-success {
  background-color: #77A163;
  border-color: #6B9358;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
   opacity: .25;
}

.nav-tabs .nav-link {
  font-size: 20px;
}

.list-group-item.active.light-header {
  background-color: #5389c3;
  border-color: #5389c3;

  &.success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
  }
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0,0,0,.09);
}

/* for known issue with ngb-modal and stacked modals */
ngb-modal-backdrop {
   z-index: 1050 !important;
}

// ie10 + bootstrap4 overrides
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // fixes stretched images in cards
  .card-group, .card-deck {
    width: 100%;

    .card {
      display: block;
    }
  }
}


/* ngx-datatable - ViewEncapsulation.None setting causes setting class issue
   - https://stackoverflow.com/questions/47198777/ngx-datatable-cellclass-not-working
   - set classes globally
*/

.ngx-datatable {
  /* REMOVE THIS - just for designing table cells */
  .datatable-body-cell-label {
    // border: solid 1px blue;
  }

  .btn-group-sm > .btn, .btn-sm {
    //padding: .25rem .5rem;
    font-size: .65rem;
    //line-height: 1.5;
    //border-radius: .2rem;
  }

  .custom() {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important;
  }

  .datatable-body-cell.custom {
    .custom();
  }
  //&#pending-returns-grid,
  &#stock-plans-grid {
    height: 300px;

    .year {
      width: 100px !important;
      text-align: center
    }
    /*.typeDescription {
      width: 120px !important;
      text-align: center;
    }
*/
    .shareOffer,
    .sharePrice,
    .totalAdjustedShareOffer,
    .totalPurchasedShares,
    .totalRepurchasedShares,
    .totalEvergreenShares {
      text-align: right
    }

    .sharePrice {
       width:50px;
    }
  }

  &#shareholders-grid {
    height: 400px;

    .name {
      //width: 170px !important;
      .material-icons {
        vertical-align: middle;
        margin-bottom: 0.2em;
      }
    }

    .checklist {
      width: 65px !important;
      text-align: center;
    }

    .edit {
      width: 75px !important;
      text-align: center;
    }

    .offered,
    .scheduled,
    .evergreen,
    .dividends {
      text-align: right
    }

    .dateTime {
      width: 130px !important;
      text-align: center;
    }

    .common {
      width: 120px !important;
      text-align: center;
    }

    .identificationId {
      width: 150px !important;
      text-align: center;
    }

    .shareholderDisplay {
      width: 250px !important;
    }

    .merchReturnForm {
      width: 300px !important;
    }

    .shortDate {
      width: 100px !important;
      text-align: center;
    }
  }
}

#pending-returns-grid.ngx-datatable .datatable-body {
  .ellipsis {font-size: 28px;line-height: 14px;}
}

.time-zone .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 180px !important;
}

.row > * {
   margin-top: 1rem !important;
}

/* Override for pretty-checkbox for wrapping text*/
.pretty {
   white-space: normal;
   width: 100%;
}

.pretty .state label {
   text-indent: 0;
   padding-left: 2rem;
}

.pretty .state label:after,
.pretty .state label:before,
.pretty.p-icon .state .icon {
   top: 0;
}
