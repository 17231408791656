@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
@import "../fonts/fonts.less";
@import "palette.less";
.text-site {
  color: @color-text !important;
}
.text-site-primary {
  color: @color-site-primary !important
}
.text-site-primary-alt {
  color: @color-site-primary-alt !important
}
.text-site-secondary {
  color: @color-site-secondary !important
}
.font-site-secondary {
   font-family: @font-family-secondary;
   font-weight: 500;
}
.text-site-tertiary {
  color: @color-site-tertiary !important
}

.text-site-lead-life {
   color: @color-lead-life;
}

.text-site-lead-education {
   color: @color-lead-education;
}

.text-site-lead-accountability {
   color: @color-lead-accountability;
}

.text-site-lead-difference {
   color: @color-lead-difference;
}

.font-site-tertiary {
   font-family: @font-family-tertiary;
   font-weight: 800;
}
.text-error {
  color: @color-error !important;
}
/*.text-fancy-cursive {
   font-family: @font-family-cursive
}*/
.text-underline {
   text-decoration: underline
}
.pointable {
   cursor: pointer;
}

.me-6, .mx-6 {
  margin-right: 3.5rem !important;
}
.me-7, .mx-7 {
  margin-right: 4rem !important;
}
.me-8, .mx-8 {
  margin-right: 4.5rem !important;
}
.me-9, .mx-9 {
  margin-right: 5rem !important;
}
.me-10, .mx-10 {
  margin-right: 5.5rem !important;
}

.fs-7 {
   font-size:.75rem!important
}
