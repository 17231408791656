@import "../utilities/palette.less";
/* SITE-PRIMARY BUTTON */
.btn-site-primary {
   background-color: @color-site-primary;
   border-color: @color-site-primary;
   color: #FFFFFF;
}

.btn-site-primary:hover, .btn-site-primary:focus, .btn-site-primary:active, .btn-site-primary.active, .open .dropdown-toggle.btn-site-primary {
   background-color: lighten(@color-site-primary, 6%);
   border-color: lighten(@color-site-primary, 6%);
   color: #FFFFFF;
}

.btn-site-primary:active, .btn-site-primary.active, .open .dropdown-toggle.btn-site-primary {
   background-image: none;
}

.btn-site-primary.disabled, .btn-site-primary.disabled:hover, .btn-site-primary.disabled:focus, .btn-site-primary.disabled:active, .btn-site-primary.disabled.active, .btn-site-primary[disabled], .btn-site-primary[disabled]:hover, .btn-site-primary[disabled]:focus, .btn-site-primary[disabled]:active, .btn-site-primary.active[disabled], fieldset[disabled] .btn-site-primary, fieldset[disabled] .btn-site-primary:hover, fieldset[disabled] .btn-site-primary:focus, fieldset[disabled] .btn-site-primary:active, fieldset[disabled] .btn-site-primary.active {
   background-color: darken(@color-site-primary, 6%);
   border-color: darken(@color-site-primary, 6%);
}

/* GRAY BUTTON */
.btn-site-secondary {
   background-color: @color-site-secondary;
   border-color: @color-site-secondary;
   color: #FFFFFF;
}

.btn-site-secondary:hover, .btn-site-secondary:focus, .btn-site-secondary:active, .btn-site-secondary.active, .open .dropdown-toggle.btn-site-secondary {
   background-color: lighten(@color-site-secondary, 6%);
   border-color: lighten(@color-site-secondary, 6%);
   color: #FFFFFF;
}

.btn-site-secondary:active, .btn-site-secondary.active, .open .dropdown-toggle.btn-site-secondary {
   background-image: none;
}

.btn-site-secondary.disabled, .btn-site-secondary.disabled:hover, .btn-site-secondary.disabled:focus, .btn-site-secondary.disabled:active, .btn-site-secondary.disabled.active, .btn-site-secondary[disabled], .btn-site-secondary[disabled]:hover, .btn-site-secondary[disabled]:focus, .btn-site-secondary[disabled]:active, .btn-site-secondary.active[disabled], fieldset[disabled] .btn-site-secondary, fieldset[disabled] .btn-site-secondary:hover, fieldset[disabled] .btn-site-secondary:focus, fieldset[disabled] .btn-site-secondary:active, fieldset[disabled] .btn-site-secondary.active {
   background-color: darken(@color-site-secondary, 6%);
   border-color: darken(@color-site-secondary, 6%);
}

/*SITE-TERTIARY BUTTON*/
.btn-site-tertiary {
   background-color: @color-site-tertiary;
   border-color: @color-site-tertiary;
   color: #FFFFFF;
}

.btn-site-tertiary:hover, .btn-site-tertiary:focus, .btn-site-tertiary:active, .btn-site-tertiary.active, .open .dropdown-toggle.btn-site-tertiary {
   background-color: lighten(@color-site-tertiary, 6%);
   border-color: lighten(@color-site-tertiary, 6%);
   color: #FFFFFF;
}

.btn-site-tertiary:active, .btn-site-tertiary.active, .open .dropdown-toggle.btn-site-tertiary {
   background-image: none;
}

.btn-site-tertiary.disabled, .btn-site-tertiary.disabled:hover, .btn-site-tertiary.disabled:focus, .btn-site-tertiary.disabled:active, .btn-site-tertiary.disabled.active, .btn-site-tertiary[disabled], .btn-site-tertiary[disabled]:hover, .btn-site-tertiary[disabled]:focus, .btn-site-tertiary[disabled]:active, .btn-site-tertiary.active[disabled], fieldset[disabled] .btn-site-tertiary, fieldset[disabled] .btn-site-tertiary:hover, fieldset[disabled] .btn-site-tertiary:focus, fieldset[disabled] .btn-site-tertiary:active, fieldset[disabled] .btn-site-tertiary.active {
   background-color: darken(@color-site-tertiary, 6%);
   border-color: darken(@color-site-tertiary, 6%);
}

/*LEAD SECTION BUTTONS*/
.btn-lead-life {
   background-color: @color-lead-life;
   border-color: @color-lead-life;
   color: #FFFFFF;
}

.btn-lead-life:hover, .btn-lead-life:focus, .btn-lead-life:active, .btn-lead-life.active, .open .dropdown-toggle.btn-lead-life {
   background-color: lighten(@color-lead-life, 6%);
   border-color: lighten(@color-lead-life, 6%);
   color: #FFFFFF;
}

.btn-lead-life:active, .btn-lead-life.active, .open .dropdown-toggle.btn-lead-life {
   background-image: none;
}

.btn-lead-life.disabled, .btn-lead-life.disabled:hover, .btn-lead-life.disabled:focus, .btn-lead-life.disabled:active, .btn-lead-life.disabled.active, .btn-lead-life[disabled], .btn-lead-life[disabled]:hover, .btn-lead-life[disabled]:focus, .btn-lead-life[disabled]:active, .btn-lead-life.active[disabled], fieldset[disabled] .btn-lead-life, fieldset[disabled] .btn-lead-life:hover, fieldset[disabled] .btn-lead-life:focus, fieldset[disabled] .btn-lead-life:active, fieldset[disabled] .btn-lead-life.active {
   background-color: darken(@color-lead-life, 6%);
   border-color: darken(@color-lead-life, 6%);
}

.btn-lead-education {
   background-color: @color-lead-education;
   border-color: @color-lead-education;
   color: #FFFFFF;
}

.btn-lead-education:hover, .btn-lead-education:focus, .btn-lead-education:active, .btn-lead-education.active, .open .dropdown-toggle.btn-lead-education {
   background-color: lighten(@color-lead-education, 6%);
   border-color: lighten(@color-lead-education, 6%);
   color: #FFFFFF;
}

.btn-lead-education:active, .btn-lead-education.active, .open .dropdown-toggle.btn-lead-education {
   background-image: none;
}

.btn-lead-education.disabled, .btn-lead-education.disabled:hover, .btn-lead-education.disabled:focus, .btn-lead-education.disabled:active, .btn-lead-education.disabled.active, .btn-lead-education[disabled], .btn-lead-education[disabled]:hover, .btn-lead-education[disabled]:focus, .btn-lead-education[disabled]:active, .btn-lead-education.active[disabled], fieldset[disabled] .btn-lead-education, fieldset[disabled] .btn-lead-education:hover, fieldset[disabled] .btn-lead-education:focus, fieldset[disabled] .btn-lead-education:active, fieldset[disabled] .btn-lead-education.active {
   background-color: darken(@color-lead-education, 6%);
   border-color: darken(@color-lead-education, 6%);
}

.btn-lead-accountability {
   background-color: @color-lead-accountability;
   border-color: @color-lead-accountability;
   color: #FFFFFF;
}

.btn-lead-accountability:hover, .btn-lead-accountability:focus, .btn-lead-accountability:active, .btn-lead-accountability.active, .open .dropdown-toggle.btn-lead-accountability {
   background-color: lighten(@color-lead-accountability, 6%);
   border-color: lighten(@color-lead-accountability, 6%);
   color: #FFFFFF;
}

.btn-lead-accountability:active, .btn-lead-accountability.active, .open .dropdown-toggle.btn-lead-accountability {
   background-image: none;
}

.btn-lead-accountability.disabled, .btn-lead-accountability.disabled:hover, .btn-lead-accountability.disabled:focus, .btn-lead-accountability.disabled:active, .btn-lead-accountability.disabled.active, .btn-lead-accountability[disabled], .btn-lead-accountability[disabled]:hover, .btn-lead-accountability[disabled]:focus, .btn-lead-accountability[disabled]:active, .btn-lead-accountability.active[disabled], fieldset[disabled] .btn-lead-accountability, fieldset[disabled] .btn-lead-accountability:hover, fieldset[disabled] .btn-lead-accountability:focus, fieldset[disabled] .btn-lead-accountability:active, fieldset[disabled] .btn-lead-accountability.active {
   background-color: darken(@color-lead-accountability, 6%);
   border-color: darken(@color-lead-accountability, 6%);
}

.btn-lead-difference {
   background-color: @color-lead-difference;
   border-color: @color-lead-difference;
   color: #FFFFFF;
}

.btn-lead-difference:hover, .btn-lead-difference:focus, .btn-lead-difference:active, .btn-lead-difference.active, .open .dropdown-toggle.btn-lead-difference {
   background-color: lighten(@color-lead-difference, 6%);
   border-color: lighten(@color-lead-difference, 6%);
   color: #FFFFFF;
}

.btn-lead-difference:active, .btn-lead-difference.active, .open .dropdown-toggle.btn-lead-difference {
   background-image: none;
}

.btn-lead-difference.disabled, .btn-lead-difference.disabled:hover, .btn-lead-difference.disabled:focus, .btn-lead-difference.disabled:active, .btn-lead-difference.disabled.active, .btn-lead-difference[disabled], .btn-lead-difference[disabled]:hover, .btn-lead-difference[disabled]:focus, .btn-lead-difference[disabled]:active, .btn-lead-difference.active[disabled], fieldset[disabled] .btn-lead-difference, fieldset[disabled] .btn-lead-difference:hover, fieldset[disabled] .btn-lead-difference:focus, fieldset[disabled] .btn-lead-difference:active, fieldset[disabled] .btn-lead-difference.active {
   background-color: darken(@color-lead-difference, 6%);
   border-color: darken(@color-lead-difference, 6%);
}

/*.btn-recruiting,
.btn-recruiting:hover,
.btn-recruiting:focus,
.btn-recruiting:active,
.btn-recruiting.active {
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#7c568f), color-stop(50%, #553A63), to(#452f50));
  background-image: -webkit-linear-gradient(#7c568f, #553A63 50%, #452f50);
  background-image: -moz-linear-gradient(top, #7c568f, #553A63 50%, #452f50);
  background-image: linear-gradient(#7c568f, #553A63 50%, #452f50);
  border: 1px solid #291d30;
  filter: none;
  color: #fff;
}*/
